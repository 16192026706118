body {
  margin: 0;
  font-size: 16px;
  font-family: 'Chivo Mono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Chivo Mono';
}

.rc-slider {
  z-index:78
}
